* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  --divider-width: 2px;
  --divider-color: #24264f;
  --default-handle-opacity: 1;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

*:focus {
  box-shadow: none !important;
  outline: none;
}

*[data-focus] {
  box-shadow: none !important;
}

::-webkit-scrollbar-track {
  border-radius: none;
  background-color: #f8f8fa;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #f8f8fa;
}

::-webkit-scrollbar-thumb {
  border-radius: none;
  background-color: rgba(71, 73, 114, 0.4);
}

.react-player {
  video {
    object-fit: cover;
  }
}
